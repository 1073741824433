.pb--120 {
	padding-bottom: 120px !important;
}
.pt--90 {
	padding-top: 90px !important;
}
.bg_color--5 {
	background: #f2f2f2;
}
.bg_color--5 a{ color: #000;}

.bg_color--4 {
	background: #e0e7ee;
}

.pt--80 {
	padding-top: 80px !important;
}
[data-overlay], [data-black-overlay], [data-white-overlay] {
	position: relative;
	z-index: 1;
}

.bg_image--17 {
	background-image: url(../images/bg-image-17.jpg);
}
.bg_image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.counterup_style--1 {
	text-align: center;
	margin-top: 40px;
}

.theme-text-white .counterup_style--1 h5.counter {
	color: #ffffff;
}

.counterup_style--1 h5.counter {
	font-size: 72px;
	line-height: 80px;
	display: inline-block;
	margin-bottom: 17px;
	position: relative;
	display: inline-block;
	color: #f9004d;
	margin-left: -13px;
}

.theme-text-white .counterup_style--1 p.description {
	color: #ffffff;
}

.counterup_style--1 p.description {
	font-size: 18px;
	line-height: 30px;
	padding: 0 5%;
	color: rgba(29, 29, 36, 0.75);
}