.pb--190 {
	padding-bottom: 80px !important;
}
.pt--120 {
	padding-top: 80px !important;
}
[data-overlay], [data-black-overlay], [data-white-overlay] {
	position: relative;
	z-index: 1;
}
.bg_image--7 {
	background-image: url(../images/bg-image-7.jpg);
}
[data-overlay="7"]::before, [data-black-overlay="7"]::before, [data-white-overlay="7"]::before {
	opacity: 0.7;
}
[data-black-overlay]::before {
	background-color: #00010c;
}
[data-overlay]::before, [data-black-overlay]::before, [data-white-overlay]::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}
.blog-single-page-title {
	position: relative;
}
.pt--100 {
	padding-top: 100px !important;
}
.blog-single-page-title h2 {
	font-weight: 700;
	line-height: 90px;
	font-size: 72px;
}

.theme-gradient {
	background: rgb(248, 31, 1);
	background-image: -ms-linear-gradient(145deg, rgb(248, 31, 1) 0%, rgb(238, 7, 110) 100%);
	background: -moz-linear-gradient(145deg, rgb(248, 31, 1) 0%, rgb(238, 7, 110) 100%);
	background: -webkit-linear-gradient(145deg, rgb(248, 31, 1) 0%, rgb(238, 7, 110) 100%);
	background: linear-gradient(145deg, rgb(248, 31, 1) 0%, rgb(238, 7, 110) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f81f01", endColorstr="#ee076e");
	-webkit-background-clip: text;
}
.theme-gradient {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: inline-block;
}

.pt--110 {
	padding-top: 110px !important;
}
.pb--70 {
	padding-bottom: 70px !important;
}
.bg_color--1 {
	background: #fff;
}
.rn-blog-details .inner-wrapper .inner {
	padding: 0 110px;
}

.rn-blog-details .inner-wrapper .inner p {
	font-size: 18px;
	line-height: 30px;
	color: rgba(29, 29, 36, 0.75);
	margin-bottom: 40px;
}
.rn-blog-details .inner-wrapper .blog-single-list-wrapper h4.title {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 30px;
}
.rn-blog-details .inner-wrapper .blog-single-list-wrapper .content ul.list-style {
	margin-bottom: 50px;
}

.rn-blog-details h4{ margin: 30px 0px 20px 0px;}

.rn-blog-details a{ color: #000;}

.list-style ul li {
	position: relative;
	padding-left: 16px;
	color: rgba(29, 29, 36, 0.75);
	font-size: 18px;
	line-height: 25px;
	display: flex;
	align-items: center;
}
.list-style ul li::before {
	position: absolute;
	width: 6px;
	height: 6px;
	background: rgba(29, 29, 36, 0.75);
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: "";
	border-radius: 100%;
}
iframe{ margin-top: 50px;}