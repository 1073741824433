@media (max-width: 374.98px) { 
    .bgSlider .carousel-caption { top: 22%;}
    .bgSlider h3{ color: #fff; font-size: 18px; }
    .ptb-50{ padding: 50px 50px;}
    .section-title h2{  font-size: 18px;}
    .product-name-on-card h5{font-size: 14px;}
    .ms-auto { margin-left: 0px !important;  }
    
}

@media (min-width: 375.98px) and (max-width: 575.98px){
    .bgSlider .carousel-caption { top: 24%;}
    .bgSlider h3{ color: #fff; font-size: 22px; }
    .ptb-50{ padding: 50px 50px;}
    .section-title h2{  font-size: 18px;}
    .product-name-on-card h5{font-size: 14px;}
    .ms-auto { margin-left: 0px !important;  }

}


@media (min-width: 576px) and (max-width: 767.98px) { 
    .bgSlider .carousel-caption { top: 24%;}
    .bgSlider h3{ color: #fff; font-size: 32px; }
    .ms-auto { margin-left: 0px !important;  }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .bgSlider .carousel-caption { top: 28%;}
    .header-content li a {  font-size: 10px; color: #363636; }
    .ms-auto { margin-left: 0px !important;  }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .navbar-light .navbar-nav .nav-link{ padding: 0px 7px;}

}


@media (min-width: 1200px) { 
    

}