@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import "bigSlider.css";
@import "button.css";
@import "fontawesome.css";
@import "footer.css";
@import "menu.css";
@import "socialmedia.css";
@import "common.css";
@import "responsive.css";
@import "faq.css";
@import "plugin.css";
@import "card.css";




body{ background-color: #fff;  font-family: 'Roboto Condensed', sans-serif; font-size: 16px; line-height: 32px; text-align: justify;}