section.banner {
    padding: 0;
    overflow: hidden;
}
.slider {
    height: 550px;
    overflow: hidden;
    position: relative;
    background: #000;
}

.row.slick-slider {
    margin: 0 -15px;
}
@media(max-width: 1100px) and (min-width: 1024px) {
    .header_menu .navbar.navbar-default .nav.navbar-nav li a {
        font-size: 14px;
        padding: 8px 5px;
    }
}
@media(max-width: 991px) {
    .header-content li {
        padding: 5px 10px;
    }
    .nav-btn, .topbar-header {
        display: none;
    }
    header.main_header_area.headerstyle-2 .navbar-header {
        display: block;
    }
    header.main_header_area.headerstyle-2 .navbar-flex {
        justify-content: space-between;
    }
}
@media(max-width: 811px) {
    .header-content {
        display: none;
    }
    header.main_header_area.headerstyle-2 .navbar-flex {
        border-top: 0;
        padding-top: 0;
    }

    header.main_header_area.headerstyle-2 .slicknav_nav{top: 78px;}
}
@media(max-width: 735px) {
    .slick-slide {
        text-align: center;
    }
    .slick-slide img {
        width: auto!important;
        display: inline-block!important;
    }
}
/* ========================================= */

/*          Banner  CSS        */

/* ======================================== */
