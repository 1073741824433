.ptb--30 {padding: 30px 0 !important;}
[data-overlay], [data-black-overlay], [data-white-overlay] {
	position: relative;
	z-index: 1;
}
.bg_image--1 {
	background-image: url(../images/bg-image-1.jpg);
}
.bg_image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
[data-black-overlay]::before {
	background-color: #00010c;
}
[data-overlay]::before, [data-black-overlay]::before, [data-white-overlay]::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
}
.text-sm-start {
	text-align: left !important;
}
ul.social-share.rn-lg-size {
	margin-top: 0 !important;
}
ul.social-share {
	padding: 0;
	margin: 0;
	margin: -6px;
}
.liststyle {
	padding: 0;
	margin: 0;
	list-style: none;
}
.justify-content-center {
	-webkit-box-pack: center !important;
	-webkit-justify-content: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.d-flex {
	display: -webkit-box !important;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
}
.liststyle {
	list-style: none;
}
/* Social icon Styles  */
ul.social-share {
    padding: 0;
    margin: 0;
    margin: -6px;
  }
  ul.social-share li {
    margin: 6px;
  }
  ul.social-share li a {
    width: 40px;
    display: inline-block;
    height: 40px;
    border: 2px solid rgba(198, 201, 216, 0.75);
    line-height: 35px;
    color: rgba(198, 201, 216, 0.75);
    border-radius: 100%;
    text-align: center;
    font-size: 14px;
  }
  ul.social-share li:hover a {
    background: #f9004d;
    border-color: #f9004d;
    color: #ffffff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
  }
  ul.social-share.color-theme li a {
    border-color: #f9004d;
    color: #f9004d;
  }
  ul.social-share.color-theme li a:hover {
    background: #f9004d;
    border-color: #f9004d;
    color: #ffffff;
  }
  ul.social-share.rn-lg-size {
    margin-top: 0 !important;
  }
  ul.social-share.rn-lg-size li a {
    width: 50px;
    height: 50px;
    line-height: 47px;
    display: block;
    font-size: 16px;
  }
  ul.social-share.social-style--2 li a {
    border: 0 none;
    width: 30px;
    height: auto;
    text-align: left;
    line-height: inherit;
    font-size: 16px;
  }
  ul.social-share.social-style--2 li:hover a {
    background: transparent;
    box-shadow: none;
  }
  ul.social-share.social-style--2.color-theme li a {
    color: #f9004d;
  }
  ul.social-share.social-style--2.color-theme li a:hover {
    color: currentColor;
  }
  ul.social-share.social-style--2.color-black li a {
    color: #1d1d24;
    opacity: 0.8;
  }
  ul.social-share.social-style--2.color-black li a:hover {
    color: #f9004d;
    opacity: 1;
  }
  
.call-to-action {
	background: linear-gradient(145deg, #033b7a 0%, #ee076e 100%);
	position: relative;
	z-index: 2;
}
.call-to-action::before {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-image: url(../images/pattern-1.png);
	content: "";
	z-index: -1;
	opacity: 0.5;
}
.ptb--120 {
	padding: 120px 0 !important;
}
.call-to-action .inner > span {
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 4px;
	display: block;
	margin-bottom: 9px;
}
.call-to-action .inner h2 {
	color: #ffffff;
	font-size: 42px;
	font-weight: 900;
	font-family: "Montserrat", sans-serif;
	line-height: 70px;
}
.call-to-action .inner a.rn-button-style--2 {
	margin-top: 30px;
	background: transparent;
}
.text-white-wrapper a.rn-button-style--2 {
	border: 2px solid #fff;
	color: #fff;
}
button.rn-button-style--2, a.rn-button-style--2 {
	color: #c6c9d8;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 2px;
	border: 2px solid #b1b4c1;
	padding: 15px 40px;
	border-radius: 6px;
	display: inline-block;
	font-weight: 500;
	transition: 0.3s;
}
button.rn-button-style--2.btn_border:hover,
a.rn-button-style--2.btn_border:hover {
  background: #f9004d;
  color: #fff;
}
button.rn-button-style--2.btn_border.btn_border--dark,
a.rn-button-style--2.btn_border.btn_border--dark {
  border: 2px solid #1f1f25;
  color: #1f1f25;
}
button.rn-button-style--2.btn_border.btn_border--dark:hover,
a.rn-button-style--2.btn_border.btn_border--dark:hover {
  background: #1f1f25;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(31, 31, 37, 0.1);
}
button.rn-button-style--2.border-opacity-half,
a.rn-button-style--2.border-opacity-half {
  border-color: rgba(31, 31, 37, 0.1);
}
button.rn-button-style--2.rn-btn-small,
a.rn-button-style--2.rn-btn-small {
  padding: 10px 20px;
}
button.rn-button-style--2.btn-primary-color:hover,
a.rn-button-style--2.btn-primary-color:hover {
  border: 2px solid #f9004d;
  background: #f9004d;
  color: #ffffff;
}
button.rn-button-style--2.btn-solid,
a.rn-button-style--2.btn-solid {
  background: #f9004d;
  color: #ffffff;
  border-color: #f9004d;
}
button.rn-button-style--2:hover,
a.rn-button-style--2:hover {
  background: #fff !important;
  color: #f9004d;
  box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  transform: translateY(-5px);
}

.button-group {
  margin: -10px;
}
.button-group a {
  margin: 10px;
}
.footer-style-2 p{ color: #fff;}