.rn-section-gap {
	padding: 120px 0;
}
.bg_color--1 {
	background: #fff;
}
.section-title h2.title {
	font-size: 60px;
	margin-bottom: 50px;
}



.blog.blog-style--1 {
	position: relative;
	overflow: hidden;
	border-radius: 6px;
	margin-top: 40px;
}
.blog.blog-style--1 .thumbnail a {
	display: block;
	position: relative;
	z-index: 1;
}
.thumbnail img {
	border-radius: 5px;
	box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
}
.w-100 {
	width: 100% !important;
}
.blog.blog-style--1 .thumbnail a::after {
	background: linear-gradient(to bottom, #fc004d 0, #000000 100%);
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	content: "";
	z-index: 1;
	opacity: 0;
}
.blog.blog-style--1 .content {
	position: absolute;
	bottom: -92px;
	width: 100%;
	padding: 50px 35px;
	z-index: 3;
}
.blog.blog-style--1 .content::before {
	position: absolute;
	content: "";
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000000 70%);
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: -1;
}

.blog.blog-style--1 .content h4.title {
	margin-bottom: 0;
    font-size: 22px;
	margin-bottom: 5px;
	color: #c6c9d8;
}
.blogtype{color: #fff;}
.blog.blog-style--1 .content h4.title a{ color: #fff;}
.blog.blog-style--1 .content .blog-btn {
	margin-top: 35px;
	transition: 0.7s;
	opacity: 0;
	visibility: hidden;
}
a.rn-btn, button.rn-btn, .rn-btn {
	padding: 0 23px;
	height: 40px;
	display: inline-block;
	line-height: 34px;
	border: 2px solid #f9004d;
	border-radius: 4px;
	font-size: 14px;
	position: relative;
	z-index: 2;
	color: #f9004d;
	letter-spacing: 0.2px;
	text-transform: uppercase;
}



a.rn-btn:hover, button.rn-btn:hover, .rn-btn:hover {
	border: 2px solid #f9004d;
	transform: translateY(-3px);
	color: #ffffff;
	background: #f9004d;
}

a.rn-btn, button.rn-btn, .rn-btn {
	padding: 0 23px;
	height: 40px;
	display: inline-block;
	line-height: 34px;
	border: 2px solid #f9004d;
	border-radius: 4px;
	font-size: 14px;
	position: relative;
	z-index: 2;
	color: #f9004d;
	letter-spacing: 0.2px;
	text-transform: uppercase;
}

/**************************************
    Blog Styles
***************************************/
/*===================
Blog Styles 
=====================*/
.blog.blog-style--1 {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    .blog.blog-style--1 {
      margin-top: 30px;
    }
  }
  .blog.blog-style--1 .thumbnail a {
    display: block;
    position: relative;
    z-index: 1;
  }
  .blog.blog-style--1 .thumbnail a::after {
    background: linear-gradient(to bottom, #fc004d 0, #000000 100%);
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;
    opacity: 0;
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .thumbnail a::after {
      opacity: 0.85;
      top: 0;
    }
  }
  .blog.blog-style--1 .content {
    position: absolute;
    bottom: -92px;
    width: 100%;
    padding: 50px 35px;
    z-index: 3;
  }
  @media only screen and (max-width: 767px) {
    .blog.blog-style--1 .content {
      padding: 40px 23px;
    }
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .content {
      bottom: 0;
    }
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .content {
      padding: 30px 20px;
    }
  }
  .blog.blog-style--1 .content::before {
    position: absolute;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000000 70%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: -1;
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .content::before {
      opacity: 0;
    }
  }
  .blog.blog-style--1 .content p.blogtype {
    font-size: 14px;
    margin-bottom: 5px;
    color: #c6c9d8;
  }
  .blog.blog-style--1 .content h4.title {
    margin-bottom: 0;
  }
  .blog.blog-style--1 .content h4.title a {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }
  .blog.blog-style--1 .content .blog-btn {
    margin-top: 35px;
    transition: 0.7s;
    opacity: 0;
    visibility: hidden;
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .content .blog-btn {
      margin-top: 22px;
    }
  }
  @media only screen and (max-width: 575px) {
    .blog.blog-style--1 .content .blog-btn {
      opacity: 1;
      visibility: visible;
    }
  }
  .blog.blog-style--1:hover .content {
    bottom: 0;
  }
  .blog.blog-style--1:hover .content::before {
    opacity: 0;
  }
  .blog.blog-style--1:hover .thumbnail a img {
    transform: scale(1.1);
  }
  .blog.blog-style--1:hover .thumbnail a::before {
    opacity: 0;
  }
  .blog.blog-style--1:hover .thumbnail a::after {
    opacity: 0.85;
    top: 0;
  }
  .blog.blog-style--1:hover .content .blog-btn {
    opacity: 1;
    visibility: visible;
  }
  
  /*----------------------
  BLog Details Styles 
  ------------------------*/
  .blog-single-page-title {
    position: relative;
  }
  .blog-single-page-title h2 {
    font-weight: 700;
    line-height: 90px;
    font-size: 72px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-single-page-title h2 {
      line-height: 70px;
      font-size: 48px;
    }
  }
  @media only screen and (max-width: 767px) {
    .blog-single-page-title h2 {
      line-height: 48px;
      font-size: 36px;
    }
  }
  .blog-single-page-title ul.blog-meta {
    margin: 0 -15px;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    bottom: -135px;
  }
  @media only screen and (max-width: 767px) {
    .blog-single-page-title ul.blog-meta {
      margin: 0 -10px;
      bottom: -105px;
    }
  }
  .blog-single-page-title ul.blog-meta li {
    font-size: 16px;
    color: #c6c9d8;
    margin: 0 15px;
    margin-bottom: 13px;
  }
  @media only screen and (max-width: 767px) {
    .blog-single-page-title ul.blog-meta li {
      font-size: 14px;
      margin: 0 10px;
      margin-bottom: 10px;
    }
  }
  .blog-single-page-title ul.blog-meta li svg {
    margin-right: 13px;
    font-size: 22px;
  }
  
  .rn-blog-details .inner-wrapper .inner {
    padding: 0 110px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rn-blog-details .inner-wrapper .inner {
      padding: 0 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .inner {
      padding: 0 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .inner {
      padding: 0 10px;
    }
  }
  .rn-blog-details .inner-wrapper .inner p {
    font-size: 18px;
    line-height: 30px;
    color: rgba(29, 29, 36, 0.75);
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .inner p {
      margin-bottom: 20px;
    }
  }
  .rn-blog-details .inner-wrapper .inner .thumbnail {
    margin: 0 -110px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .inner .thumbnail {
      margin: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .inner .thumbnail {
      margin: 0;
    }
  }
  .rn-blog-details .inner-wrapper .inner .thumbnail img {
    width: 100%;
  }
  .rn-blog-details .inner-wrapper .inner .rn-blog-quote {
    font-size: 24px;
    line-height: 40px;
    color: #1f1f25;
    font-weight: 400;
    text-align: center;
    padding: 0 9%;
    margin-top: 67px;
    margin-bottom: 67px;
    position: relative;
    z-index: 2;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .rn-blog-details .inner-wrapper .inner .rn-blog-quote {
      padding: 0 4%;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .inner .rn-blog-quote {
      padding: 0 4%;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .inner .rn-blog-quote {
      padding: 0 4%;
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 33px;
    }
  }
  @media only screen and (max-width: 575px) {
    .rn-blog-details .inner-wrapper .inner .rn-blog-quote {
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 29px;
    }
  }

  .rn-blog-details .inner-wrapper .blog-single-list-wrapper {
    margin-right: -110px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper {
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper {
      margin-right: 0;
    }
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail {
    width: 55%;
    padding-right: 30px;
    margin-right: 0;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail {
      width: 100%;
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail {
      width: 100%;
      padding-right: 0;
    }
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail img {
    width: 100%;
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail span {
    color: rgba(29, 29, 36, 0.75);
    font-size: 14px;
    display: block;
    text-align: left;
    padding-top: 10px;
    padding-left: 110px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .thumbnail span {
      font-size: 13px;
      padding-left: 0;
      margin-bottom: 23px;
    }
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper h4.title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper h4.title {
      margin-bottom: 16px;
    }
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper .content {
    width: 45%;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .content {
      width: 100%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .content {
      width: 100%;
    }
  }
  .rn-blog-details .inner-wrapper .blog-single-list-wrapper .content ul.list-style {
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 767px) {
    .rn-blog-details .inner-wrapper .blog-single-list-wrapper .content ul.list-style {
      margin-bottom: 25px;
    }
  }
  