ul{ margin: 0px; padding: 0px;}
a{ text-decoration: none;}
.header-content { padding: 12px 0;   background: #014087;  border-bottom: 1px solid #033b7a;}
.header-content .links { display: inline-block;}
.header-content li a {font-size: 14px; color: #fff;}
.header-content li { display: inline-block;  padding: 0px 20px;  border-right: 1px solid #033b7a;  margin: 0;}
.links-right{ float: right;}



.header_menu .navbar.navbar-default .nav.navbar-nav {
    float: none;
    text-align: right;
    display: inline-block;
    background: transparent;
}
.header_menu .navbar.navbar-default .nav.navbar-nav li {
    float: none;
    display: inline-block;
    margin-right: 0px;
    background: transparent;
}
.header_menu .navbar.navbar-default .nav.navbar-nav li:hover a, .header_menu .navbar.navbar-default .nav.navbar-nav li.active a {
    background: transparent;
    color: #ef2853;
}


header .navbar-default .navbar-nav>li.active, header .navbar-default .navbar-nav>li.active>a, header .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
    background: #f33807;
}
.navbar-light .navbar-nav .nav-link {
    background: transparent;
    color: #333333e8;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 0px;
    letter-spacing: .45px;
    line-height: inherit;
    -webkit-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    transition: all 150ms linear;
    border: none;
    padding: 12px 8px;
}

    
button.rn-button-style--1, a.rn-button-style--1 {
	color: #fff !important;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 2px;
	border: 2px solid #b1b4c1;
	padding: 15px 40px;
	border-radius: 6px;
	display: inline-block;
	font-weight: 500;
	transition: 0.3s;
    background: #f9004d !important;
}
button.rn-button-style--1.btn_border:hover,
a.rn-button-style--1.btn_border:hover {
  background: #f9004d;
  color: #fff;
}
button.rn-button-style--1.btn_border.btn_border--dark,
a.rn-button-style--1.btn_border.btn_border--dark {
  border: 2px solid #1f1f25;
  color: #ffff;
}
button.rn-button-style--1.btn_border.btn_border--dark:hover,
a.rn-button-style--1.btn_border.btn_border--dark:hover {
  background: #1f1f25;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(31, 31, 37, 0.1);
}
button.rn-button-style--1.border-opacity-half,
a.rn-button-style--1.border-opacity-half {
  border-color: rgba(31, 31, 37, 0.1);
}
button.rn-button-style--1.rn-btn-small,
a.rn-button-style--1.rn-btn-small {
  padding: 10px 20px;
}
button.rn-button-style--1.btn-primary-color:hover,
a.rn-button-style--1.btn-primary-color:hover {
  border: 2px solid #f9004d;
  background: #f9004d;
  color: #ffffff;
}
button.rn-button-style--1.btn-solid,
a.rn-button-style--1.btn-solid {
  background: #f9004d;
  color: #ffffff;
  border-color: #f9004d;
}
button.rn-button-style--1:hover,
a.rn-button-style--1:hover {
  background: #014087 !important;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(249, 0, 77, 0.1);
  transform: translateY(-5px);
}

