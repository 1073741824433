
.news{ background-color: #000;}
.register{ position:fixed; left:0px; bottom: 2%; z-index: 999; width: 130px;}

.app{ position: fixed; right: -2px; bottom: 40%; z-index: 999; }
.app ul{ margin:0px; padding:0px;}
.app ul li{ list-style-type:none; padding:5px 5px !important; margin:5px 0px !important; transition: 0.3s;}

.partner{ position: fixed; left: -2px; bottom: 40%; z-index: 999;}
.partner ul{ margin:0px; padding:0px;}
.partner ul li{ list-style-type:none; padding:5px 5px !important; margin:5px 0px !important; transition: 0.3s;}

.social1{ position:fixed; top:70%; left:0%;  z-index:999 }
.social1 ul{ margin:0px; padding:0px;}
.social1 ul li{ list-style-type:none; padding:5px 5px !important; margin:5px 0px !important; transition: 0.3s;}
.social1 ul li{ color:#fff;}

.table{ border:1px #f1f1f1 solid; text-transform:capitalize; margin: 25px 0px;}
.table tr td{ border:1px #f1f1f1 solid; }
.table tr th{ border:1px #1f073b solid; background-color:#2d0d52; color:#fff; padding:8px; }
.table tr th a{ color: #fff;}

.table tr {border: 1px solid #D9E4E6;}
.table tr:nth-child(odd) { background-color: #EAF3F3;}


.blink-text{
    color: #000;
    font-weight: bold;
    font-size: 16px;
    animation: blinkingText 2s infinite;
}
@keyframes blinkingText{
    0%		{ color: #10c018;}
    25%		{ color: #ffff;}
    50%		{ color: #ef0a1a;}
    75%		{ color: #fff000;}
    100%	{ color: #04a1d5;}
}